import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/Components/HomeComponents/Banner.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/Components/HomeComponents/BlogSection.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/Components/HomeComponents/ManicureSection.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/Components/HomeComponents/NewsLatter.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/Components/HomeComponents/SecondBanner.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/Components/HomeComponents/WorkingHours.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/Components/Shared/FAQSection.jsx");
