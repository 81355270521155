const getBlogsData = () => {
  const blogPosts = [
    {
      id: 1,
      publishDate: "2024-10-01",
      title: "Top 5 Nail Care Tips for Healthy Nails",
      publisher: "Ruma Nails",
      description: `
        <h2 class="text-2xl font-semibold mb-4">Achieving and Maintaining Healthy Nails</h2>
        <p class="mb-4">
          Consistent care and attention are key to maintaining strong, resilient, and beautiful nails. In this blog, we share five essential tips that will help you avoid common nail problems such as breakage, splitting, and infections.
        </p>
        <h3 class="text-xl font-semibold mb-3">Key Tips for Nail Health:</h3>
        <ul class="list-disc list-inside mb-4 space-y-2">
          <li><strong>Moisturize Regularly</strong>: Hydrate your nails and cuticles to prevent dryness and cracking.</li>
          <li><strong>Trim and Shape Properly</strong>: Use the right tools for regular trimming to encourage healthy growth and reduce damage.</li>
          <li><strong>Avoid Harsh Chemicals</strong>: Limit exposure to harmful chemicals in nail polishes and cleaning products.</li>
          <li><strong>Nail-Friendly Diet</strong>: Include biotin-rich foods like eggs and nuts in your diet for stronger nails.</li>
          <li><strong>Gentle Nail Treatments</strong>: Avoid aggressive filing or buffing, and opt for gentle treatments.</li>
        </ul>
        <p class="mb-4">
          By following these tips, you can enjoy strong, healthy nails that are naturally beautiful year-round.
        </p>
      `,
      coverImg:
        "https://res.cloudinary.com/dydv6uxzo/image/upload/v1729587066/RumaNails/Blogs/promo-07_eg1zku.jpg",
    },
    {
      id: 2,
      publishDate: "2024-10-05",
      title: "The Best Nail Shapes for Every Hand Type",
      publisher: "Ruma Nails",
      description: `
        <h2 class="text-2xl font-semibold mb-4">Choosing the Perfect Nail Shape</h2>
        <p class="mb-4">
          The right nail shape can enhance the beauty of your hands and complement your personal style. In this blog, we explore popular nail shapes and how to select the best one for your hand type.
        </p>
        <h3 class="text-xl font-semibold mb-3">Popular Nail Shapes:</h3>
        <ul class="list-disc list-inside mb-4 space-y-2">
          <li><strong>Almond</strong>: Sleek, feminine, and perfect for elongating fingers.</li>
          <li><strong>Square</strong>: Bold and durable, ideal for wider nail beds.</li>
          <li><strong>Stiletto</strong>: Dramatic and eye-catching, though requires more maintenance.</li>
          <li><strong>Coffin (Ballerina)</strong>: Trendy with a flat tip, perfect for longer nails.</li>
          <li><strong>Round</strong>: Low-maintenance and versatile, great for everyday wear.</li>
        </ul>
        <h3 class="text-xl font-semibold mb-3">Choosing the Right Shape:</h3>
        <ul class="list-disc list-inside mb-4 space-y-2">
          <li>Consider your lifestyle and how much maintenance you're willing to handle.</li>
          <li>Think about your natural nail length and shape.</li>
          <li>Choose a shape that matches your personality and style.</li>
        </ul>
        <p class="mb-4">
          By understanding your options, you can confidently choose the nail shape that complements your hands and allows you to express your style.
        </p>
      `,
      coverImg:
        "https://res.cloudinary.com/dydv6uxzo/image/upload/v1729587066/RumaNails/Blogs/promo-06_r1b5a4.jpg",
    },
    {
      id: 3,
      publishDate: "2024-10-10",
      title: "Gel vs. Acrylic Nails: Which is Better?",
      publisher: "Ruma Nails",
      description: `
        <h2 class="text-2xl font-semibold mb-4">Gel vs. Acrylic Nails: A Comprehensive Comparison</h2>
        <p class="mb-4">
          Gel and acrylic nails are popular options for nail enhancements, but which one is better for you? This detailed comparison breaks down the benefits and drawbacks of each to help you make an informed decision.
        </p>
        <h3 class="text-xl font-semibold mb-3">Gel Nails:</h3>
        <ul class="list-disc list-inside mb-4 space-y-2">
          <li><strong>Appearance</strong>: Offers a natural, glossy look that closely mimics real nails.</li>
          <li><strong>Flexibility</strong>: More flexible than acrylics, reducing the risk of cracking or breakage.</li>
          <li><strong>Application</strong>: Requires curing under a UV or LED lamp, ensuring a quick-drying, durable finish.</li>
          <li><strong>Maintenance</strong>: Easier to remove, though more frequent touch-ups may be needed.</li>
        </ul>
        <h3 class="text-xl font-semibold mb-3">Acrylic Nails:</h3>
        <ul class="list-disc list-inside mb-4 space-y-2">
          <li><strong>Durability</strong>: Incredibly strong and long-lasting, ideal for those seeking low-maintenance extensions.</li>
          <li><strong>Customization</strong>: Perfect for creating complex shapes and designs due to their rigid structure.</li>
          <li><strong>Application</strong>: Created by mixing liquid monomer with powder, which hardens naturally without UV light.</li>
          <li><strong>Maintenance</strong>: Requires more intensive upkeep but can last longer than gel nails.</li>
        </ul>
        <h3 class="text-xl font-semibold mb-3">Key Differences to Consider:</h3>
        <ul class="list-disc list-inside mb-4 space-y-2">
          <li><strong>Cost</strong>: Acrylics are typically more affordable upfront, while gels may require less long-term maintenance.</li>
          <li><strong>Durability</strong>: Acrylics are more robust, making them better suited for those with active hands.</li>
          <li><strong>Nail Health</strong>: Gel nails are gentler on your natural nails, making them a healthier option for some.</li>
        </ul>
        <p class="mb-4">
          Whether you choose gel or acrylic, your decision should depend on your lifestyle, nail health, and desired aesthetic. Both options offer beautiful, long-lasting results when properly cared for.
        </p>
      `,
      coverImg:
        "https://res.cloudinary.com/dydv6uxzo/image/upload/v1729587065/RumaNails/Blogs/promo-05_nruinb.jpg",
    },
  ];

  return blogPosts;
};

export default getBlogsData;
