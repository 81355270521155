"use client";

import Image from "next/image";
import { useRef } from "react";
import useScrollAnimation from "@/Hooks/AnimationRelated/useScrollAnimation";
import Link from "next/link";

const ManicureSection = () => {
  // for animation triggering  purpose on specific section
  const leftSectionRef = useRef(null);
  const rightSectionRef = useRef(null);

  useScrollAnimation(leftSectionRef, "animate-slide-left");
  useScrollAnimation(rightSectionRef, "animate-slide-right");
  // for animation triggering  purpose end
  return (
    <>
      <section className="bg-primaryColorWhiteShade overflow-hidden space-y-5 lg:space-y-10 xl:space-y-16 p-5 lg:p-10 xl:p-20">
        {/* first container */}
        <div
          ref={leftSectionRef}
          className="bg-[#fff9f9] flex flex-col md:flex-row justify-center items-center  rounded-lg"
        >
          <figure className="flex-1">
            <Link href={"/services"}>
              <Image
                src={
                  "https://res.cloudinary.com/dydv6uxzo/image/upload/v1729421020/RumaNails/SkilledArtist/img-3_970x_dzjgze.webp"
                }
                className="rounded-lg  border-2 border-primaryColorWhiteShade"
                quality={100}
                width={1000}
                height={1000}
                alt="Manicure Nails"
              />
            </Link>
          </figure>
          <div className="flex-1">
            <div className=" space-y-2 lg:space-y-5 2xl:space-y-16 p-5 md:px-6 xl:p-16 2xl:p-24">
              <Link href={"/services"} className="hover:underline">
                <h2 className="text-2xl xl:text-4xl font-semibold text-gray-700 ">
                  Get the best nails and manicure your nails
                </h2>
              </Link>
              <p className="text-sm lg:text-lg tracking-wider  text-justify text-gray-500">
                Experience top-notch manicures that elevate your style. Our
                skilled technicians create beautiful, healthy nails tailored to
                you. Treat yourself and feel confident!
              </p>
              <br /> <br />
              <Link href={"/services"}>
                <button
                  className="border-b-2 border-primaryColor hover:text-primaryColor transition-all 
                  duration-200 font-semibold text-gray-700 text-xl"
                >
                  Explore Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        {/* Second container */}
        <div
          ref={rightSectionRef}
          className="bg-[#fff9f9] flex flex-col md:flex-row-reverse justify-center items-center rounded-lg"
        >
          <figure className="flex-1">
            <Link href={"/services"} className="hover:underline">
              <Image
                src={
                  "https://res.cloudinary.com/dydv6uxzo/image/upload/v1729421020/RumaNails/SkilledArtist/img-4_970x_q7h2bn.webp"
                }
                className="rounded-lg  border-2 border-primaryColorWhiteShade"
                quality={100}
                width={1000}
                height={1000}
                alt="Manicure Nails"
              />
            </Link>
          </figure>
          <div className="flex-1">
            <div className=" space-y-2 lg:space-y-5 2xl:space-y-16 p-5 md:px-6 xl:p-16 2xl:p-24">
              <Link href={"/services"} className="hover:underline">
                <h2 className="text-2xl xl:text-4xl font-semibold text-gray-700 ">
                  Get a new look for your nails
                </h2>
              </Link>
              <p className="text-sm lg:text-lg tracking-wider  text-justify text-gray-500">
                Revamp your nails with fresh styles and colors! Our experts
                create stunning designs that reflect your personality and boost
                your confidence.
              </p>
              <br />
              <br />
              <Link href={"/services"}>
                <button className="border-b-2 border-primaryColor hover:text-primaryColor transition-all duration-200 font-semibold text-gray-700 text-xl">
                  Explore Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ManicureSection;
