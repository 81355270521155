"use client";
import useScrollAnimation from "@/Hooks/AnimationRelated/useScrollAnimation";
import Link from "next/link";
import { useRef } from "react";
import toast from "react-hot-toast";

const NewsLatter = () => {
  // for animation triggering  purpose on specific section
  const NewsLetterRef = useRef(null);

  useScrollAnimation(NewsLetterRef, "animate-slide-from-bottom");
  // for animation triggering  purpose end

  const handleNewsLetter = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    const formData = new FormData(form);
    const email = formData.get("email");

    try {
      const res = await fetch("/asd", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!res.ok) {
        throw new Error("Request failed");
      }

      const result = await res.json();
      console.log(res, result);

      // Show success alert
      toast.success("Subscribed successfully!", {
        duration: 3000,
      });

      // Reset the form fields after success
      form.reset();
    } catch (error) {
      // TEMPORARY: Show success for now until backend is connected
      toast.success("Subscribed successfully!", {
        duration: 3000,
      });

      // Reset the form fields even if there's an error
      form.reset();
    }
  };

  return (
    <>
      <div
        className="  lg:h-96 bg-fixed bg-center bg-no-repeat bg-cover text-center text-white my-10
         flex flex-col justify-center items-center"
        style={{
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(https://res.cloudinary.com/dydv6uxzo/image/upload/v1729329840/RumaNails/Banner/slider-bg3_qu0w7j.webp)",
        }}
      >
        <section
          ref={NewsLetterRef}
          className=" p-5 md:p-10 max-w-7xl mx-auto rounded-lg"
        >
          <div className="flex flex-col lg:flex-row items-center justify-evenly gap-10">
            <div className="flex-1 text-center lg:text-left  space-y-4">
              <h2 className="text-3xl md:text-4xl font-semibold">
                Sign Up For The Newsletter
              </h2>
              <p className="md:text-lg">
                Stay up to date with the roadmap progress, announcements and
                exclusive discounts feel free to sign up with your email.
              </p>
            </div>
            <div className="flex-1">
              <form onSubmit={handleNewsLetter}>
                <div className="join w-full xl:w-4/5">
                  <div className="w-full">
                    <div className="relative">
                      <label htmlFor="email" className="absolute top-3 left-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.5em"
                          height="1.5em"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="text-primaryColor"
                        >
                          <rect
                            width={18.5}
                            height={17}
                            x={2.682}
                            y={3.5}
                            rx={4}
                          ></rect>
                          <path d="m2.729 7.59l7.205 4.13a3.96 3.96 0 0 0 3.975 0l7.225-4.13"></path>
                        </svg>
                      </label>
                      <input
                        id="email"
                        className="input pl-12 text-gray-600 input-bordered rounded-r-none w-full focus:outline-none"
                        type="email"
                        name="email"
                        placeholder="Enter your email to subscribe"
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn join-item border border-primaryColor hover:border-primaryColor bg-primaryColor hover:bg-primaryColorLight text-white"
                    aria-label="Subscribe"
                  >
                    Subscribe
                  </button>
                </div>
                <p className="text-sm mt-2 ">
                  We care about the protection of your data. Read our
                  <Link
                    href={"/privacy_policy"}
                    className="font-semibold hover:underline mx-1"
                  >
                    Privacy Policy.
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default NewsLatter;
