"use client";
import useScrollAnimation from "@/Hooks/AnimationRelated/useScrollAnimation";
import Link from "next/link";
import { useRef } from "react";

const WorkingHours = () => {
  // for animation triggering  purpose on specific section
  const OpeningHoursRef = useRef(null);
  const WorkingDetailsRef = useRef(null);

  useScrollAnimation(OpeningHoursRef, "animate-slide-left");
  useScrollAnimation(WorkingDetailsRef, "animate-slide-right");
  // for animation triggering  purpose end
  return (
    <>
      <section>
        <div
          className=" max-w-7xl mx-auto flex flex-col-reverse md:flex-row justify-center 
        items-center rounded-lg p-2 md:p-10 overflow-hidden"
        >
          <figure ref={OpeningHoursRef} className="flex-1 w-full">
            <div className="overflow-x-auto px-4 w-full max-w-lg mx-auto">
              <h1 className="text-xl font-medium text-gray-400">
                Opening Hours
              </h1>
              <table className="table-auto  w-full text-left text-sm lg:text-xl">
                <tbody className="">
                  <tr className="border-b-2  border-gray-200 border-dashed">
                    <td className="py-3 lg:py-5  font-medium text-gray-600">
                      Mon – Wed
                    </td>
                    <td className="py-3 px-5  lg:p-5 text-center ">------</td>
                    <td className="py-3 lg:py-5 font-bold text-right ">
                      10:00 AM - 8:00 PM
                    </td>
                  </tr>
                  <tr className="border-b-2 border-gray-200 border-dashed">
                    <td className="py-3 lg:py-5 font-medium text-gray-600">
                      Thursday
                    </td>
                    <td className="py-3 px-5 lg:p-5 text-center">------</td>
                    <td className="py-3 lg:py-5 font-bold text-right">
                      10:00 AM - 8:00 PM
                    </td>
                  </tr>
                  <tr className="border-b-2 border-gray-200 border-dashed">
                    <td className="py-3 lg:py-5 font-medium text-gray-600">
                      Friday
                    </td>
                    <td className="py-3 px-5 lg:p-5 text-center">------</td>
                    <td className="py-3 lg:py-5 font-bold text-right">
                      10:00 AM - 8:00 PM
                    </td>
                  </tr>
                  <tr className="border-b-2 border-gray-200 border-dashed">
                    <td className="py-3 lg:py-5 font-medium text-gray-600">
                      Sunday
                    </td>
                    <td className="py-3 px-5 lg:p-5 text-center">------</td>
                    <td className="py-3 lg:py-5 font-bold text-right">
                      10:00 AM - 9:00 PM
                    </td>
                  </tr>
                  <tr>
                    <td className="py-3 lg:py-5 font-medium text-gray-600">
                      Saturday
                    </td>
                    <td className="py-3 px-5 lg:p-5 text-center">------</td>
                    <td className="py-3 lg:py-5 font-bold text-right">
                      10:00 AM - 9:00 PM
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </figure>
          <div ref={WorkingDetailsRef} className="flex-1 w-full">
            <div className=" space-y-2 lg:space-y-5 2xl:space-y-16 p-5 md:px-6 xl:p-16 2xl:p-24">
              <h1 className="text-xl font-medium text-gray-400">
                Time Schedule
              </h1>
              <h2 className="text-2xl xl:text-4xl font-semibold text-gray-700 ">
                Working Hours
              </h2>
              <p className="text-sm lg:text-lg tracking-wider  text-justify text-gray-500">
                Plan your perfect manicure session with our convenient time
                schedule. Whether it&apos;s a quick touch-up or a luxurious nail
                treatment, our skilled technicians are available to fit your
                needs. Find the perfect time and treat yourself to beautiful,
                healthy nails.
              </p>

              <div className="flex justify-start mb-10">
                <Link
                  href={"/contact_us#storeLocation"}
                  className="px-2 py-2 font-semibold flex items-center gap-2 text-sm md:text-lg border-b-2  border-primaryColor text-gray-700 transition-all duration-500 hover:text-primaryColor group"
                >
                  Shop Address
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 md:w-6 md:h-6 ml-auto transition-transform duration-300 ease-in-out transform group-hover:translate-x-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkingHours;
