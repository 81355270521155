"use client";

import useScrollAnimation from "@/Hooks/AnimationRelated/useScrollAnimation";
import getBlogsData from "@/Lib/getBlogsData";
import Image from "next/image";
import Link from "next/link";
import { useRef } from "react";

const BlogSection = () => {
  const blogsData = getBlogsData();
  const blog1 = blogsData[0];
  const blog2 = blogsData[1];
  const blog3 = blogsData[2];

  // for animation triggering  purpose on specific section
  const blog1Ref = useRef(null);
  const blog2Ref = useRef(null);
  const blog3Ref = useRef(null);

  useScrollAnimation(blog1Ref, "animate-slide-left");
  useScrollAnimation(blog2Ref, "animate-slide-from-bottom");
  useScrollAnimation(blog3Ref, "animate-slide-right");
  // for animation triggering  purpose end

  return (
    <>
      <section id="blogs">
        <div className="mx-auto w-full max-w-7xl px-2 md:px-5 py-16 xl:px-16 md:py-24 lg:py-10 overflow-hidden">
          <div className="flex flex-col items-center ">
            <div className="mb-10  text-center ">
              <p className="mx-auto mt-4 text-[#636262]">The Glossy Guide</p>
              <h2 className="text-3xl text-gray-700 font-medium capitalize md:text-5xl">
                Nail Care for Every Season
              </h2>
            </div>
            <div className="mb-8 grid gap-3 w-full grid-cols-1 md:mb-12 md:grid-cols-3 md:gap-4 lg:mb-10">
              {/* first blog */}
              <Link
                ref={blog1Ref}
                href={`/blog_details/${blog1?.id}`}
                className="w-full grid-cols-1 group"
              >
                <div
                  className="flex flex-col-reverse gap-4 rounded-md font-medium
                 text-gray-700 lg:px-2 bg-[#fffdfd] shadow-sm border border-primaryColorWhiteShade md:border-none md:shadow-none p-2 md:p-0"
                >
                  <Image
                    src={blog1?.coverImg}
                    width={1000}
                    height={1000}
                    alt="blogPost2"
                    className="inline-block h-full w-full rounded object-cover"
                  />
                  <div className="flex w-full flex-col items-start justify-start py-4 mb-3 xl:mb-7">
                    <h3 className="text-gray-400 my-3">
                      Posted On {blog1?.publishDate}
                    </h3>
                    <p className="text-xl font-medium md:text-2xl lg:text-3xl group-hover:underline">
                      {blog1?.title}
                    </p>
                  </div>
                </div>
              </Link>
              {/* second blog */}
              <Link
                ref={blog2Ref}
                href={`/blog_details/${blog2?.id}`}
                prefetch={true}
              >
                <div className="relative mx-auto w-full h-full overflow-hidden rounded-md shadow">
                  <Image
                    src={blog2?.coverImg}
                    width={1000}
                    height={1000}
                    priority={false}
                    loading="lazy"
                    className="w-full h-full object-cover"
                    alt="Product Image"
                  />
                  <div className="absolute inset-0 z-10 bg-gradient-to-t from-[#2c2c2c77]"></div>
                  <div className="absolute inset-x-0 bottom-0 z-20 p-4 text-left">
                    <div className="flex w-full flex-col items-start justify-start p-2">
                      <h3 className="text-gray-200 my-3">
                        Posted On {blog2?.publishDate}
                      </h3>
                      <p className="text-xl text-white font-bold md:text-2xl lg:text-3xl hover:underline transition">
                        {blog2?.title}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              {/* third blog */}
              <Link ref={blog3Ref} href={`/blog_details/${blog3?.id}`}>
                <div className="flex w-full grid-cols-1 flex-col gap-4 rounded-md pb-2 font-medium text-gray-700 lg:px-2 group bg-[#fffdfd] shadow-sm border border-primaryColorWhiteShade md:border-none md:shadow-none p-2 md:p-0">
                  <Image
                    src={blog3?.coverImg}
                    width={1000}
                    height={1000}
                    alt="blogPost3"
                    className="inline-block h-full w-full rounded object-cover"
                  />
                  <div className="flex w-full flex-col items-start justify-start py-4 ">
                    <h3 className="text-gray-400 my-3">
                      Posted On {blog3?.publishDate}
                    </h3>
                    <p className="text-xl font-medium md:text-2xl lg:text-3xl group-hover:underline">
                      {blog3?.title}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogSection;
