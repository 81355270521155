"use client";
import useScrollAnimation from "@/Hooks/AnimationRelated/useScrollAnimation";
import Image from "next/image";
import { useRef } from "react";

const SecondBanner = ({ LeftAlign, bannerImg }) => {
  // for animation triggering  purpose on specific section
  const secondBannerRef = useRef(null);

  useScrollAnimation(secondBannerRef, "animate-slide-from-bottom");
  // for animation triggering  purpose end
  return (
    <>
      <div ref={secondBannerRef} className=" relative w-full">
        <div
          className={`absolute inset-0 flex items-center ${
            LeftAlign ? "justify-start" : "justify-end text-right"
          }`}
        >
          <div
            className={`max-w-56 md:max-w-md lg:max-w-xl xl:max-w-2xl 2xl:max-w-4xl space-y-1 md:space-y-2 lg:space-y-4 xl:space-y-6  w-full text-center p-4 md:p-8 ${
              LeftAlign ? "animate-slide-in-left" : "animate-slide-in-right"
            } ${LeftAlign ? " xl:ml-12" : "xl:mr-12"}`}
          >
            <p
              className={`text-sm md:text-lg lg:text-2xl xl:text-3xl  tracking-wide  text-gray-900`}
            >
              We want to make every girl <br /> pretty, happy, and loved!
            </p>
            <h2
              className={`text-4xl md:text-7xl lg:text-8xl xl:text-9xl font-semibold  text-gray-700`}
            >
              20% OFF
            </h2>
            <p
              className={`text-sm md:text-lg lg:text-2xl xl:text-3xl  tracking-wide  text-gray-900`}
            >
              on Manicure + Gel Polish
            </p>
            <button className="btn btn-xs md:btn-md xl:btn-lg border-primaryColor text-white hover:bg-primaryColor bg-primaryColor  md:text-xl hover:border-primaryColorLight group">
              Explore Now
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-4 h-4 md:w-6 md:h-6 ml-auto transition-transform duration-300 ease-in-out transform group-hover:translate-x-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>
          </div>
        </div>
        <Image
          src={bannerImg || ""}
          alt="Banner Image"
          className="w-full h-auto object-cover"
          width={1920}
          height={800}
          quality={100}
        />
      </div>
    </>
  );
};

export default SecondBanner;
